import { createRouter, createWebHistory } from "vue-router";
import { isAuthenticated } from "@/helpers/authHelper"; // Importing the isAuthenticated function
import EmptyPage from "@/views/EmptyPage.vue";

// Lazy-load routes
const HomePage = () => import("@/views/HomePage.vue");
const MyProperty = () => import("@/views/MyProperty.vue");
const LoginPage = () => import("@/views/LoginPage.vue");
const LogoutPage = () => import("@/views/LogoutPage.vue");
const AboutUs = () => import("@/views/AboutUs.vue");
const FaqContent = () => import("@/views/FaqContent.vue");
const TermsAndConditions = () => import("@/views/TestPage.vue");
const OurServices = () => import("@/views/OurServices.vue");
const ServerError = () => import("@/views/ServerError.vue");
const ShareIt = () => import("@/views/ShareIt.vue");
const PreList = () => import("@/views/PreList.vue");
const GetStarted = () => import("@/views/GetStarted.vue");
const CalendarEvents = () => import("@/views/CalendarEvents.vue");
const TestPage = () => import("@/views/TestPage.vue");
const NotFound = () => import("@/views/NotFound.vue"); // Assuming you've created a 404 page
const Marketing = () => import("@/views/MarketingPage/Page.vue");
const MarketingProperties = () => import("@/views/MarketingProperties.vue");
const Prompts = () => import("@/views/PromptPage/Page.vue");
const Draft = () => import("@/views/DraftPage/Page.vue");
const Notifications = () => import("@/views/NotificationsPage");
const Completed = () => import("@/views/CompletedPage/Page.vue");
const RelevantDocumentsPage = () => import("@/views/RelevantDocumentsPage.vue");
const PostList = () => import("@/views/PostList.vue");

// chat
const SearchAddress = () => import("@/views/Chat/SearchAddress.vue");
const ChatPage = () => import("@/views/Chat/ChatPage.vue");
const PromptPage = () => import("@/views/PromptPage.vue");
const routes = [
  {
    path: "/home",
    component: HomePage,
    name: "/homepage",
    meta: { title: "BELeEF" },
  },
  {
    path: "/",
    component: EmptyPage,
    name: "/homepage",
    meta: { title: "BELeEF", requiresAuth: true },
  },
  {
    path: "/prompts",
    component: PromptPage,
    name: "/prompts",
    meta: { requiresAuth: true },
  },
  { path: "/property", component: MyProperty, meta: { requiresAuth: true } },
  { path: "/login", name: "Login", component: LoginPage },
  { path: "/logout", component: LogoutPage },
  { path: "/about-us", component: AboutUs },
  { path: "/faq", component: FaqContent },
  { path: "/our-services", component: OurServices },
  { path: "/terms-and-conditions", component: TermsAndConditions },
  { path: "/share/:id", component: ShareIt },
  { path: "/getstarted", component: GetStarted },
  { path: "/:pathMatch(.*)*", name: "not-found", component: NotFound },
  { path: "/server-error", name: "server-error", component: ServerError },
  { path: "/prelist/:id", component: PreList },
  // { path: '/test', component: RecommendedSalesProcess, meta: { title: 'Test' } },
  { path: "/test", component: TestPage, meta: { title: "Test" } },
  {
    path: "/calendar/events",
    component: CalendarEvents,
    meta: { requiresAuth: true, title: "My Calendar Events" },
  },
  {
    path: "/marketing/properties",
    component: MarketingProperties,
    name: "MarketingProperties",
    meta: { requiresAuth: true },
  },
  {
    path: "/marketing/prompts",
    component: Prompts,
    name: "Prompts",
    meta: { requiresAuth: true },
  },
  {
    path: "/marketing/notifications",
    component: Notifications,
    name: "Notification",
    meta: { requiresAuth: true },
  },
  {
    path: "/marketing/draft/:locationId",
    component: Draft,
    name: "Draft",
    meta: { requiresAuth: true },
  },
  {
    path: "/marketing/relevant-docs",
    component: RelevantDocumentsPage,
    name: "RelevantDocs",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/marketing/draft/public/:locationId",
    component: Draft,
    name: "DraftPublic",
  },
  {
    path: "/marketing/completed/:locationId",
    component: Completed,
    name: "CompletedPage",
  },
  {
    path: "/marketing",
    component: Marketing,
    name: "Marketing",
    meta: {
      title: "Marketing",
      requiresAuth: true,
    },
  },

  {
    path: "/postlist/:address/:id",
    component: PostList,
    meta: { requiresAuth: false },
  },

  // chat routes start
  {
    path: "/chat",
    component: SearchAddress,
    name: "SearchAddress",
    meta: { requiresAuth: true },
  },
  {
    path: "/chat/:property_id/:address",
    component: ChatPage,
    name: "ChatPage",
    meta: { requiresAuth: true },
  },
  // chat routes end
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, top: 80 };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  try {
    if (
      to.matched.some((record) => record.meta.requiresAuth) &&
      !isAuthenticated()
    ) {
      next("/login");
    } else {
      document.title = to.meta.title || "BELeEF";
      next();
    }
  } catch (error) {
    console.error("Error in router guard:", error);
    //next('/');
    next("/server-error");
  }
});

export default router;
