<template>
  <div
    v-show="!displayMarketplaceImages"
    id="search-box"
    class="w-full h-full flex flex-col items-center justify-center"
  >
    <div class="max-w-2xl mx-auto w-full bg-white px-4 py-2" v-if="showDiv">
      <div class="w-full flex justify-center">
        <div class="input-container">
          <input
            ref="addressField"
            id="ship-address"
            name="ship-address"
            class="bg-neutral-100 rounded-md w-full py-2.5 pl-3 pr-8"
            required
            autocomplete="off"
            placeholder="Enter Address Here"
          />
          <span class="clear-icon" @click="clearSearch"
            ><i class="fas fa-times"></i
          ></span>
        </div>
      </div>
      <div
        v-if="errorMessage"
        class="alert alert-warning alert-dismissible fade show error"
        role="alert"
      >
        <strong>Error!</strong> {{ errorMessage }}.
      </div>
    </div>
    <div v-if="loading" class="flex items-center justify-center">
      <i class="fas fa-spinner fa-spin text-xl"></i>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
// import { Loader } from "@googlemaps/js-api-loader";
import AOS from "aos";
import "aos/dist/aos.css";
import { useRouter } from "vue-router";
import apiClient from "@/services/api.js";
import { Loader } from "@googlemaps/js-api-loader";

export default {

  setup() {

    const addressField = ref(null);
    const loading = ref(false);

    const errorMessage = ref(null);

    const router = useRouter();

    const showDiv = ref(true);
    const location = ref(null);

    const clearSearch = () => {
      if (addressField.value) {
        addressField.value.value = "";
      }
    };

    AOS.init();

    const componentForm = {
      street_number: "short_name",
      route: "long_name",
      locality: "long_name",
      administrative_area_level_1: "short_name",
      postal_code: "short_name",
    };

    const createChat = async (propertyData) => {
      try {
        loading.value = true;
        const payload = {
          property_id: propertyData.property_id,
          address: propertyData.address
            ? propertyData.address
            : propertyData.data.address,
          data: propertyData.data ? propertyData.data : propertyData,
        };

        const response = await apiClient.post("/api/v1/chat/", payload);
        if (response.data?.success) {
          return true;
        }
      } catch (error) {
        console.error(error.message);
        loading.value = false;
      } finally {
        loading.value = false;
      }
    };

    const initAutocomplete = () => {
      // const loader = getGoogleMapsLoader();
      const loader = new Loader({
        apiKey: process.env.VUE_APP_MAP_API_KEY,
        version: "weekly",
        libraries: ["places"],
      });
      console.log(loader);

      loader
        .load()
        .then(() => {
          const autocomplete = new window.google.maps.places.Autocomplete(
            addressField.value,
            {
              componentRestrictions: { country: ["au"] },
              fields: ["address_components", "geometry", "formatted_address"],
              types: ["geocode"],
            }
          );

          autocomplete.addListener("place_changed", async () => {
            const place = autocomplete.getPlace();
            if (place && place.address_components) {
              let addr = "";
              let unitNumber = null;

              for (const component of place.address_components) {
                const componentType = component.types[0];
                if (component.types.includes("subpremise")) {
                  unitNumber = component.long_name + "/";
                }

                if (componentForm[componentType]) {
                  const val = component[componentForm[componentType]];
                  if (addr) {
                    addr += " ";
                  }
                  addr += val;
                }
              }
              if (unitNumber) {
                addr = unitNumber + addr;
              }

              const nswPattern = /NSW \d{4}$/;
              if (!nswPattern.test(addr)) {
                errorMessage.value =
                  "Only NSW properties are allowed for searching.";
                return;
              }

              location.value = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
              };

              // if (!isAuthenticated()) {
              //   router.push({ name: "Login" });
              //   return;
              // }
              errorMessage.value = "";
              loading.value = true;
              const responseData = await searchProperty(addr);
              if (!responseData) {
                errorMessage.value = "No data available";
                return;
              } else {
                console.log(window.google);
                const response = await createChat(responseData);
                showDiv.value = false;
                if (response) {
                  router.push(
                    `/chat/${responseData.property_id}/${
                      responseData.address
                        ? encodeURIComponent(responseData.address)
                        : encodeURIComponent(responseData.data.address)
                    }`
                  );
                }
              }
            } else {
              // Logic when the input is cleared
            }
          });
          const autocompleteDropdown = document.querySelector(".pac-container");
          autocompleteDropdown.style.top = "auto";
          autocompleteDropdown.style.bottom = "100%";
        })
        .catch((error) => {
          console.log(error);
        });
    };

    if (errorMessage.value) {
      setTimeout(() => {
        errorMessage.value = null;
      }, 5000);
    }

    const searchProperty = async (address) => {
      try {
        const response = await apiClient.get("/api/v1/property/search", {
          params: {
            address: address,
          },
        });

        if (response && response.data) {
          return response.data;
        } else {
          errorMessage.value = "No data returned from the server.";
        }
      } catch (error) {
        console.log("Error fetching comparable properties:", error);
      } finally {
        loading.value = false;
      }
    };

    onMounted(initAutocomplete);

    return {
      addressField,
      loading,
      errorMessage,
      showDiv,
      location,
      clearSearch,
      searchQuery: "",
      selectedProcessId: null,
    };
  },
};
</script>

<style>
@import "~@fortawesome/fontawesome-free/css/all.css";
.error {
  margin: 10px 0;
}

.input-container {
  position: relative;
  width: 100%;
}

.clear-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
}

.form-control,
.form-select {
  border-radius: 0.2rem;
}
</style>
